import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../../components/Layout";
import Seo from "../../components/seo";
import ServiceListItem from "../../components/ServiceListItem";
import ContactUsBlock from "../../components/ContactUsBlock";
import ogIMage from "../../images/og.png";
import { Link } from "gatsby";
import SedinPartners from "../../components/Sedin-partners";
const ServicesPage = ({ location }) => {
    const sedinServices = useStaticQuery(graphql`
        {
            allContentfulServices(
                sort: { fields: title, order: DESC }
                filter: { featureInFrontend: { eq: true } }
            ) {
                nodes {
                    id
                    description {
                        description
                    }
                    title
                    slug
                    solutions {
                        name
                        slug
                    }
                }
            }
        }
    `);

    return (
        <Layout>
            <Seo
                title="Sedin Technologies Sevices Offerings"
                image={`https://sedintechnologies.com${ogIMage}`}
                url={location.href}
                description={
                    "We offer innovative, integrated business solutions and services for enterprises and emerging businesses to help you manage and sustain your company’s growth."
                }
            />
            <section className="services-hero">
                <div className="container container--first">
                    <div className="text">
                        <div className="section-tag gap-2x">Services</div>
                        <h2 className="text-h2 text-fw-medium text-clr-primary">
                            Innovative and integrated solutions for enterprises and emerging
                            businesses.
                        </h2>
                    </div>
                </div>
            </section>
            <section className="our-services">
                <div className="container container--py">
                    <div className="services-wrapper">
                        {sedinServices.allContentfulServices.nodes.map((service, index) => (
                            <ServiceListItem
                                key={index}
                                title={service.title}
                                description={service.description.description}
                                slug={service.slug}
                                index={index}
                                solutions={service.solutions}
                                isSalesforce={service.title.toLowerCase().includes("salesforce")}
                            />
                        ))}
                    </div>
                    <div className="services-call-to-action">
                        <Link to="/contact/" className="sdn-cta-btn">
                            Contact us
                        </Link>
                    </div>
                </div>
            </section>
            <section className="home-partners">
                <div className="container container--mid-v2">
                    <SedinPartners />
                </div>
            </section>
            <section className="about-contact">
                <div className="container container--last">
                    <ContactUsBlock />
                </div>
            </section>
        </Layout>
    );
};

export default ServicesPage;
