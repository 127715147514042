import React from "react";
import { Link } from "gatsby";

const ServiceListItem = ({ title, description, slug, index, solutions, key, isSalesforce }) => {
    return (
        <div className="service-item">
            <div className="service-item__list">
                {/* <div className="order">0{index + 1}</div> */}
                <div className="order">{index + 1 < 10 ? `0${index + 1}` : index + 1}</div>
                <div className="content">
                    <h3 className="name text-p1 text-fw-medium gap-1x">{title}</h3>
                    <p className="description text-p4 text-fw-regular text-clr-secondary gap-1x">
                        {description}
                    </p>
                    {isSalesforce ? (
                        <a
                            href="https://sedcloudcrm.com/"
                            className="sdn-link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Explore
                        </a>
                    ) : (
                        <Link className="sdn-link" to={`/services/${slug}/`}>
                            Explore
                        </Link>
                    )}
                </div>
                <div className="solutions">
                    {solutions.map((el, i) => (
                        <div key={i}>
                            <p className="text-p4 text-fw-medium">{el.name}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ServiceListItem;
